<template>
  <main id="content" role="main">
    <div class="container" v-loading="loading">
      <div style="text-align: center; padding-top: 28px">
        <h3 style="text-align: center">
          Verify & Publish Contract Source Code
        </h3>
        <small class="text-tip text-strong"
          >COMPILER TYPE AND VERSION SELECTION</small
        >
      </div>

      <hr
        style="
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          border-top: 1px solid #e7eaf3;
        "
      />

      <!-- input data start -->
      <div v-bind:style="{ display: step == 'input' ? 'block' : 'none' }">
        <div style="display: flex; margin-top: 2.25rem">
          <img
            style="
              margin-top: -15px;
              margin-bottom: 10px;
              margin-right: 10px;
              vertical-align: middle;
              width: 115px;
            "
            src="../assets/svg/contract/undraw_Security_on_s9ym.svg"
          />

          <span class="text-tip">
            Source code verification provides <b>transparency</b> for users
            interacting with smart contracts. By uploading the source code,
            Etherscan will match the compiled code with that on the blockchain.
            Just like contracts, a "smart contract" should provide end users
            with more information on what they are "digitally signing" for and
            give users an opportunity to audit the code to independently verify
            that it actually does what it is supposed to do.
          </span>
        </div>

        <div style="margin-top: 2rem; margin-left: 3rem; margin-right: 3rem">
          <div>
            <span>
              Please enter the Contract Address you would like to verify
            </span>
            <el-input
              v-model="contract"
              placeholder="0x"
              style="display: block; margin-top: 0.5rem"
            />
          </div>

          <div style="margin-top: 1rem; display: none">
            <span> Please select Compiler Type </span>
            <el-select
              v-model="sourceType"
              placeholder="Please Select"
              style="display: block; margin-top: 0.5rem"
            >
              <el-option
                v-for="item in sourceTypes"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div style="margin-top: 1rem">
            <span> Please select Compiler Version </span>
            <el-select
              v-model="compileVersion"
              placeholder="Please Select"
              style="display: block; margin-top: 0.5rem"
            >
              <el-option
                v-for="item in compileVersions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div style="margin-top: 1rem; display: none">
            <span> Please select Open Source License Type </span>
            <a
              href="/contract-license-types"
              target="_blank"
              data-container="body"
              data-toggle="popover"
              data-placement="top"
              data-original-title=""
              title=""
              data-content="Contract Source Code License Type, click for more info"
            >
              <i class="far fa-info-circle"></i>
            </a>

            <el-select
              v-model="licenseType"
              placeholder="Please Select"
              style="display: block; margin-top: 0.5rem"
            >
              <el-option
                v-for="item in licenseTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <!--  More Setting -->
        <div
          style="
            margin-top: 1rem;
            background-color: #fff;
            border-radius: 0.5rem;
            border: 1px solid #e7eaf3;
            margin-left: 3rem;
            margin-right: 3rem;
          "
        >
          <span id="ContentPlaceHolder1_collapseContent_span">
            <a
              class="d-block collapsed"
              href="#ContentPlaceHolder1_collapseContent"
              data-toggle="collapse"
              role="button"
              style="padding: 0.75rem"
              aria-expanded="false"
              aria-controls="ContentPlaceHolder1_collapseContent"
            >
              <span style="color: #606266 !important">
                Misc Settings
                <span style="color: #77838f !important">
                  (Runs, EvmVersion & License Type settings)
                </span>
                <!-- <span class="card-arrow-more">More</span> -->
                <!-- <span class="card-arrow-less">Less</span> -->
              </span>
              <span
                style="float: right"
                class="card-btn-arrow card-btn-arrow--up ml-2"
              >
                <span class="fas fa-arrow-up small"></span>
              </span>
            </a>
          </span>

          <div id="ContentPlaceHolder1_collapseContent" class="collapse">
            <!-- upload -->
            <div style="background: rgb(231, 234, 243); height: 1px" />

            <div class="card-body">
              <div class="row align-items-center mn-3">
                <div
                  class="
                    col-md-4
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="(Applicable when Optimization = Yes) <br>Optimize for how many times you intend to run the code. Lower values will optimize more for initial deployment cost, higher values will optimize more for high-frequency usage."
                  ></i
                  >Runs
                  <el-input
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Do not change if you are unsure. Previous versions of truffle defaulted to a value of 0"
                    v-model="run"
                    style="display: block; margin-top: 0.5rem"
                  />
                </div>

                <div
                  class="
                    col-md-4
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="When you compile your contract code you can specify the Ethereum virtual machine version to compile for to avoid particular features or behaviours."
                  ></i
                  >EVM Version to target

                  <el-select
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="A list of target EVM versions and the compiler-relevant changes introduced at each version. Backward compatibility is not guaranteed between each version."
                    v-model="evm"
                    placeholder="Please Select"
                    style="display: block; margin-top: 0.5rem"
                  >
                    <el-option
                      v-for="item in evms"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>

                <div
                  class="
                    col-md-4
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Select the option you used when compiling this contract"
                  ></i
                  >Optimization
                  <el-select
                    v-model="optimization"
                    placeholder="Please Select"
                    style="display: block; margin-top: 0.5rem"
                  >
                    <el-option
                      v-for="item in optimizations"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- chose files -->
        <div
          style="
            margin-top: 1rem;
            background-color: #fff;
            border-radius: 0.5rem;
            border: 1px solid #e7eaf3;
            margin-left: 3rem;
            margin-right: 3rem;
            padding: 0.75rem;
            display: flex;
            flex-wrap: wrap;
            display: -webkit-flex; /* Safari */
          "
        >
          <el-button
            style="
              height: 2rem;
              margin-top: 0.3rem;
              margin-bottom: 0.3rem;
              margin-right: 0.5rem;
            "
            @click="changeUploadType()"
            size="mini"
            type="info"
          >
            {{ isDir ? "Dir" : "File" }}</el-button
          >
          <el-upload
            id="file"
            accept=".sol"
            :auto-upload="false"
            multiple
            ref="upload"
            :show-file-list="true"
            :on-change="selectFile"
          >
            <!-- <i class="el-icon-plus avatar-uploader-icon" /> -->

            <div
              style="
                background-color: #fefefe;
                border-radius: 0.5rem;
                border: 1px solid #e7eaf3;
                padding: 0.75rem;
              "
            >
              <span>Please select the Solidity (*.sol) files for upload *</span>
            </div>
          </el-upload>
        </div>

        <!-- lib -->
        <div
          style="
            margin-top: 1rem;
            background-color: #fff;
            border-radius: 0.5rem;
            border: 1px solid #e7eaf3;
            margin-left: 3rem;
            margin-right: 3rem;
            display: none;
          "
        >
          <span id="ContractLibrary_span">
            <a
              class="d-block collapsed"
              href="#ContractLibrary"
              data-toggle="collapse"
              role="button"
              style="padding: 0.75rem"
              aria-expanded="false"
              aria-controls="ContractLibrary"
            >
              <span style="color: #606266 !important">
                Contract Library Address
                <span style="color: #77838f !important">
                  (for contracts that use libraries, supports up to 10
                  libraries)
                </span>
              </span>
              <span
                style="float: right"
                class="card-btn-arrow card-btn-arrow--up ml-2"
              >
                <span class="fas fa-arrow-up small"></span>
              </span>
            </a>
          </span>

          <div id="ContractLibrary" class="collapse">
            <div style="background: rgb(231, 234, 243); height: 1px" />
            <div class="card-body">
              <template v-for="(item, index) in libs" :key="index">
                <div
                  class="row align-items-center mn-3"
                  style="margin-bottom: 0.5rem"
                >
                  <div
                    class="
                      col-md-6
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0
                      text-tip
                    "
                  >
                    Library_{{ index + 1 }} Name:
                    <el-input
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="This option ONLY applies to contracts that use libraries. The source code should contain the concatenated contract and library source code in a single page"
                      v-model="item.name"
                      style="display: block; margin-top: 0.2rem"
                    />
                  </div>
                  <div
                    class="
                      col-md-6
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0
                      text-tip
                    "
                  >
                    Library_{{ index + 1 }} Contract Address:
                    <el-input
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="Corresponding Contract Library Address"
                      v-model="item.address"
                      style="display: block; margin-top: 0.2rem"
                    />
                  </div>
                </div>
              </template>

              <template v-if="libs.length < 10">
                <el-button @click="addLibrary()" type="info"
                  >Add Library
                </el-button>
              </template>
            </div>
          </div>
        </div>

        <!-- result for compile -->
        <div
          style="margin-top: 1rem; margin-left: 3rem; margin-right: 3rem"
          :style="{ display: hasResult ? 'block' : 'none' }"
        >
          <span> Compile Result</span>
          <div
            style="
              margin-top: 0.5rem;
              background-color: #fff;
              border-radius: 0.5rem;
              padding-bottom: 1rem;
              border: 1px solid #e7eaf3;
            "
          >
            <el-scrollbar max-height="400px">
              <template
                v-for="(item, filaName, index) in compileResults"
                :key="index"
              >
                <!--  contracts -->
                <div
                  style="
                    margin-top: 1rem;
                    background-color: #fff;
                    border-radius: 0.5rem;
                    border: 1px solid #e7eaf3;
                    margin-left: 1rem;
                    margin-right: 1rem;
                  "
                >
                  <span :id="'compile_result_' + index + '_span'">
                    <a
                      class="d-block"
                      :href="'#compile_result_' + index"
                      data-toggle="collapse"
                      role="button"
                      style="padding: 0.75rem"
                      aria-expanded="true"
                      :aria-controls="'compile_result_' + index"
                    >
                      <span style="color: #606266 !important">
                        {{ filaName }}
                      </span>
                      <span
                        style="float: right"
                        class="card-btn-arrow card-btn-arrow--up ml-2"
                      >
                        <span class="fas fa-arrow-up small"> </span>
                      </span>
                    </a>
                  </span>
                  <div :id="'compile_result_' + index" class="collapse show">
                    <div
                      style="
                        background: rgb(231, 234, 243);
                        height: 1px;
                        margin-bottom: 0.5rem;
                      "
                    />

                    <template v-for="(contract, name) in item" :key="name">
                      <div
                        v-if="name != 'code'"
                        style="
                          padding-bottom: 0.5rem;
                          padding-start: 0.5rem;
                          padding-end: 0.5rem;
                        "
                      >
                        <el-radio
                          v-model="select"
                          :label="filaName + '@' + name"
                          border
                          @change="onSelectChange"
                          style="width: 100% !important"
                          >{{ name }}{{ contract.contract ? "：" : ""
                          }}{{ contract.contract }}</el-radio
                        >
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <!-- errors -->
              <div
                style="
                  margin-top: 1rem;
                  background-color: #fff;
                  border-radius: 0.5rem;
                  border: 1px solid #e7eaf3;
                  margin-left: 1rem;
                  margin-right: 1rem;
                "
              >
                <span :id="'compile_error_span'">
                  <a
                    class="d-block"
                    href="#compile_error"
                    data-toggle="collapse"
                    role="button"
                    style="padding: 0.75rem"
                    aria-expanded="true"
                    aria-controls="compile_error"
                  >
                    <span style="color: #606266 !important">
                      Errors Count: {{ compileErrors.length }}
                    </span>
                    <span
                      style="float: right"
                      class="card-btn-arrow card-btn-arrow--up ml-2"
                    >
                      <span class="fas fa-arrow-up small"> </span>
                    </span>
                  </a>
                </span>
                <div id="compile_error" class="collapse show">
                  <div
                    style="
                      background: rgb(231, 234, 243);
                      height: 1px;
                      margin-bottom: 0.5rem;
                    "
                  />

                  <template v-for="(item, index) in compileErrors" :key="index">
                    <div
                      style="
                        margin-bottom: 0.5rem;
                        padding-start: 0.5rem;
                        padding-end: 0.5rem;
                      "
                    >
                      <el-input readonly v-model="item.value" />
                    </div>
                  </template>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>

        <!-- service -->
        <div style="text-align: center; margin-top: 1rem">
          <el-checkbox style="text-align: center" v-model="serviceTag" label="">
            <span style="color: #606266 !important"
              >I agree to the
              <a href="javascript:void(0)"> terms of service</a></span
            >
          </el-checkbox>
        </div>

        <!-- button -->
        <div style="text-align: center; margin-top: 0.5rem">
          <el-button @click="compile()" type="primary">Compile </el-button>
          <!-- showPublishDialog -->
          <el-button
            @click="showPublishDialog()"
            type="primary"
            style="margin-left: 2rem"
            :disabled="select == ''"
            >Publish
          </el-button>
          <el-button
            @click="verify()"
            type="primary"
            style="margin-left: 2rem"
            :disabled="contract == '' || select == ''"
            >Verify
          </el-button>
        </div>
      </div>
    </div>

    <el-dialog
      title="Publish Contract"
      v-model="dialogVisible"
      append-to-body
      center
    >
      <div v-if="publishEnable" style="text-align: center; margin-bottom: 1rem">
        <i class="fa fa-spin fa-spinner mr-1" />
        <span style="margin-left: 0.5rem"
          >Create Contract transaction <b>Pending...</b></span
        >
      </div>
      <div
        v-if="compileContract != ''"
        style="text-align: center; margin-bottom: 1rem"
      >
        <i class="fas fa-check-circle mr-1" style="color: green"></i>
        <span style="margin-left: 0.5rem; text-align: center"
          ><a href="javascript:void(0)" @click="toContract">{{
            compileContract
          }}</a></span
        >
        <span style="display: block; font-size: 0.5rem" class="text-tip">
          (You should check your contract if on explorer Before you verify it!)
        </span>
      </div>
      <template v-if="contractConstructor.length == 0">
        <div style="margin-bottom: 0.5rem">No Constructor of Contract</div>
      </template>
      <el-scrollbar class="cropper-content">
        <template v-for="(item, index) in contractConstructor" :key="index">
          <div style="margin-bottom: 0.5rem">
            <span>{{ item.name }}:</span>
            <el-input
              style="width: auto !important; margin-left: 0.5rem"
              v-model="item.value"
              :placeholder="item.type"
            />
          </div>
        </template>
      </el-scrollbar>
      <template #footer>
        <!-- <div v-if="contractConstructor.length > 0" class="dialog-footer"> -->
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Close</el-button>
          <el-button
            type="primary"
            :loading="uploadLoading"
            :disabled="publishEnable"
            @click="publish()"
            >Create</el-button
          >
        </div>

        <!-- <div v-if="compileErrors.length > 0" class="dialog-footer">
          <el-button @click="dialogVisible = false">Close</el-button>
        </div> -->
      </template>
    </el-dialog>
  </main>

  <div id="push" style="margin-top: 3rem"></div>
</template>
<script>
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import { toRaw } from "@vue/reactivity";
import { importForPopover } from "../../src/assets/js/utils.js";
import { ElMessage } from "element-plus";
import NCWeb3 from "../assets/js/web3.js";
export default {
  name: "Address",
  data() {
    return {
      step: "input",
      files: [],
      sourceType: "",
      sourceTypes: [],
      compileVersion: "",
      compileVersions: [],
      licenseType: "",
      licenseTypes: [],
      contract: "",
      serviceTag: true,
      libs: [{}],
      loading: false,
      evm: "",
      evms: [],
      optimization: "No",
      optimizations: ["Yes", "No"],
      run: 200,
      dialogVisible: false,
      conpileData: {},
      contractConstructor: [],
      contractConstructorabi: false,
      compileResults: {},
      hasResult: false,
      compileErrors: [],
      publishEnable: false,
      compileContract: "",
      select: "",
      isDir: false,
      labelSelect: "",
    };
  },
  methods: {
    selectFile(file, fileList) {
      if (!this.strEndWith(file.name, ".sol")) {
        this.$refs.upload.handleRemove(file);
      }
      this.files = fileList;
      // console.dir(toRaw(this.files));
    },
    strEndWith(src, endStr) {
      if (!src || src == "") {
        return false;
      }
      var d = src.length - endStr.length;
      return d >= 0 && src.lastIndexOf(endStr) == d;
    },
    changeUploadType() {
      this.isDir = !this.isDir;
      this.$refs.upload.clearFiles();
      document.getElementsByClassName("el-upload__input")[0].webkitdirectory =
        this.isDir;
      this.files = [];
    },
    getPath(obj) {
      if (obj) {
        if (window.navigator.userAgent.indexOf("MSIE") >= 1) {
          obj.select();
          return document.selection.createRange().text;
        } else if (window.navigator.userAgent.indexOf("Firefox") >= 1) {
          if (obj.files) {
            return obj.files.item(0).getAsDataURL();
          }
          return obj.value;
        }
        return obj.value;
      }
    },
    compile() {
      if (true) {
        this.compileLocal();
        // return;
      }

      if (!this.serviceTag) {
        ElMessage.info("Please check terms of service");
        return;
      }
      var fs = toRaw(this.files);
      if (fs.length == 0) {
        ElMessage.warning("Please upload at least one file");
        return;
      }
      this.compileContract = "";
      this.loading = true;
      var forms = new FormData();
      for (var i = 0; i < fs.length; i++) {
        if (fs[i].webkitRelativePath != "") {
          fs[i].cacheName = fs[i].name;
          fs[i].name = fs[i].webkitRelativePath;
        }
        forms.append("file", fs[i].raw);
      }
      forms.append("commit", this.compileVersion);
      forms.append("license", this.licenseType);
      forms.append("libs", JSON.stringify(this.libs));
      forms.append("optimization", this.optimization);
      forms.append("evm", this.evm);
      forms.append("run", this.run);

      PostNet.PostForm(
        PostNetUrl.compileWithFile,
        (res) => {
          for (var i = 0; i < fs.length; i++) {
            fs[i].name = fs[i].cacheName;
          }
          this.loading = false;
          if (res.code == 20000) {
            this.select = "";
            this.contractConstructor = [];
            this.contractConstructorabi = false;
            this.compileErrors = [];
            this.compileResults = {};
            this.hasResult = false;
            if (res.data.errors != null && res.data.errors.length != 0) {
              this.hasResult = true;
              this.loading = false;
              for (var i = 0; i < res.data.errors.length; i++) {
                this.compileErrors.push({
                  value: JSON.stringify(res.data.errors[i]),
                });
              }
              // this.dialogVisible = true;
              // this.publishEnable = false;
              // return;
            }
            if (res.data.contracts) {
              this.compileResults = res.data.contracts;
              this.hasResult = true;
            }
          } else {
            this.loading = false;
            ElMessage.error(res.message);
          }
        },
        (err) => {
          this.loading = true;
          for (var i = 0; i < fs.length; i++) {
            fs[i].name = fs[i].cacheName;
          }
          ElMessage.error(err);
        },
        forms
      );
    },
    verify() {
      if (!this.conpileData.byteCode || this.conpileData.byteCode == "") {
        ElMessage.error("compile error,can't do this!");
        return;
      }
      var fs = toRaw(this.files);
      if (fs.length == 0) {
        ElMessage.warning("Please upload at least one file");
        return;
      }
      var forms = new FormData();
      for (var i = 0; i < fs.length; i++) {
        if (fs[i].webkitRelativePath != "") {
          fs[i].cacheName = fs[i].name;
          fs[i].name = fs[i].webkitRelativePath;
        }
        forms.append("file", fs[i].raw);
      }
      forms.append("commit", this.compileVersion);
      forms.append("license", this.licenseType);
      forms.append("libs", JSON.stringify(this.libs));
      forms.append("optimization", this.optimization);
      forms.append("evm", this.evm);
      forms.append("run", this.run);
      forms.append("address", this.contract);
      forms.append("abi", this.labelSelect);
      this.loading = true;
      var _this = this;
      PostNet.PostForm(
        PostNetUrl.verifyContract,
        (res) => {
          for (var i = 0; i < fs.length; i++) {
            fs[i].name = fs[i].cacheName;
          }
          setTimeout(function () {
            _this.loading = false;
          }, 500);
          if (res.code == 20000) {
            ElMessage.success(res.message);
            // we should to contract
          } else {
            ElMessage.error(res.message);
          }
        },
        (err) => {
          setTimeout(function () {
            _this.loading = false;
          }, 500);
          ElMessage.error(err);
          for (var i = 0; i < fs.length; i++) {
            fs[i].name = fs[i].cacheName;
          }
        },
        forms
      );
    },
    onSelectChange(label) {
      var srt = label.split("@");
      this.labelSelect = label;
      if (srt.length != 2) {
        ElMessage.error("Please chose one Contract");
        return;
      }
      // dialog for publish
      this.contractConstructor = [];
      this.contractConstructorabi = false;
      this.conpileData = this.compileResults[srt[0]][srt[1]];
      this.conpileData.code = this.compileResults[srt[0]].code;
      for (var i = 0; i < this.conpileData.abi.length; i++) {
        var item = this.conpileData.abi[i];
        if (item.type == "constructor") {
          this.contractConstructor.push(...item.inputs);
          this.contractConstructorabi = true;
          break;
        }
      }
      if (this.conpileData.contract) {
        this.contract = contract;
      }
    },
    showPublishDialog() {
      if (!this.contractConstructorabi) {
        ElMessage.error("This Contract can not publish");
        return;
      }
      this.dialogVisible = true;
    },
    publish() {
      if (!this.conpileData.byteCode || this.conpileData.byteCode == "") {
        ElMessage.error("compile error,can't do this!");
        return;
      }
      if (this.select == "") {
        ElMessage.error("Please chose one Contract");
        return;
      }
      this.publishEnable = true;
      var args = [];
      for (var i = 0; i < this.contractConstructor.length; i++) {
        args.push(this.contractConstructor[i].value);
      }
      NCWeb3.PublishContract(
        (contract) => {
          this.publishEnable = false;
          this.dialogVisible = false;
          this.compileContract = contract;
          this.contract = contract;
          this.conpileData.contract = contract;
        },
        (err) => {
          this.dialogVisible = false;
          this.publishEnable = false;
          ElMessage.error(err);
        },
        this.conpileData.byteCode,
        this.conpileData.abi,
        args
      );
    },
    addLibrary() {
      this.libs.push({});
    },
    getSolVersions() {
      this.loading = true;
      PostNet.PostWith(
        PostNetUrl.solcCompileViersions,
        (res) => {
          this.loading = false;
          if (res.data) {
            this.compileVersions = res.data.versions;
            this.licenseTypes = res.data.license;
            this.evms = res.data.evm;
          } else {
            ElMessage.error(res.message);
          }
        },
        (err) => {
          this.loading = false;
          ElMessage.error(err);
        }
      );
    },
    toContract() {
      console.log(this.compileContract);
    },
    compileLocal() {},
  },
  mounted() {
    importForPopover();
    this.getSolVersions();
  },
};
</script>

<style scoped>
.text-tip {
  color: #8c98a4 !important;
}
.text-strong {
  font-weight: 700;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.75rem;
}
.cropper-content {
  width: auto;
  height: 300px;
}
</style>
